// THIS FILE IS MOSTLY A COPY OF TimeGrid COMPONENT FROM Balance-Development/react-big-calendar (hm-main branch)
// IT IS UPDATED TO SUPPORT OUR CUSTOM VIEW

import clsx from 'clsx'
import * as animationFrame from 'dom-helpers/animationFrame'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import memoize from 'memoize-one'
import { withStyles } from 'tss-react/mui'
import { cx } from '@emotion/css'
import { Views } from '@nlevchuk/react-big-calendar'

import getWidth from 'dom-helpers/width'
import TimeGridHeader from '@nlevchuk/react-big-calendar/lib/TimeGridHeader'
import { notify } from '@nlevchuk/react-big-calendar/lib/utils/helpers'
import { inRange, sortEvents } from '@nlevchuk/react-big-calendar/lib/utils/eventLevels'
import Resources from '@nlevchuk/react-big-calendar/lib/utils/Resources'
import dayShiftTypes from '../../../configs/dayShiftTypes'
import Event from '../Event'

const styles = theme => ({
  hmRbcStaffContainer: {
    width: 160,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch'
  },
  hmRbcDaysContainer: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'stretch'
  },
  hmRbcWeekRow: {
    display: 'flex',
    flexBasis: '100px',
    flexGrow: 1,
    borderBottom: '1px solid #ddd',
    minHeight: 200,
    borderLeft: 'none'
  },
  hmRbcOneStaff: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '5px 10px',
    fontFamily: 'Roboto',
    borderRight: '1px solid #ddd',
    height: '100%'
  },
  hmRbcDay: {
    minWidth: 140,
    width: '100%',
    flexGrow: 1,
    background: '#ececec',
    cursor: 'pointer',
    overflowY: 'auto',
    '&.isWorkingDay': {
      background: 'white'
    },
    '&:not(:nth-child(2))': {
      borderLeft: '1px solid #ddd'
    }
  }
})

class CustomTimeGrid extends Component {
  memoizedResources = memoize((resources, accessors) =>
    Resources(resources, accessors)
  )

  constructor(props) {
    super(props)

    this.state = { gutterWidth: undefined, isOverflowing: null, isFlexboxContainer: false, flexContainerStaticRows: {} }

    this.scrollRef = React.createRef()
    this.contentRef = React.createRef()
    this.daysContainerRef = React.createRef()
    this.daysInnerContainerRef = React.createRef()
    this._scrollRatio = null
  }

  UNSAFE_componentWillMount() {
    this.calculateScroll()
  }

  componentDidMount() {
    this.checkOverflow()

    if (this.props.width == null) {
      this.measureGutter()
    }

    this.applyScroll()

    window.addEventListener('resize', this.handleResize)

    this.useFlexContainerIfNeeded()
  }

  useFlexContainerIfNeeded = () => {
    if (this.daysContainerRef.current.clientHeight > this.daysInnerContainerRef.current.clientHeight) {
      // Get rows that should not be shrinked.
      let i = 0
      const flexContainerStaticRows = {}
      const equalBlockHeight = Math.floor(this.daysContainerRef.current.clientHeight / this.daysInnerContainerRef.current.childElementCount)
      this.daysInnerContainerRef.current.childNodes.forEach(node => {
        if (node.clientHeight > equalBlockHeight) flexContainerStaticRows[i] = node.clientHeight;
        i++
      })
      this.setState({isFlexboxContainer: true, flexContainerStaticRows})
    }
  }

  handleScroll = e => {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollLeft = e.target.scrollLeft
    }
  }

  handleResize = () => {
    animationFrame.cancel(this.rafHandle)
    this.rafHandle = animationFrame.request(this.checkOverflow)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)

    animationFrame.cancel(this.rafHandle)

    if (this.measureGutterAnimationFrameRequest) {
      window.cancelAnimationFrame(this.measureGutterAnimationFrameRequest)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.width == null) {
      this.measureGutter()
    }

    this.applyScroll()
    if (prevProps.range !== this.props.range) {
      if (this.state.isFlexboxContainer) {
        this.setState({
          isFlexboxContainer: false
        }, this.useFlexContainerIfNeeded)
      } else {
        this.useFlexContainerIfNeeded()
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { range, scrollToTime, localizer } = this.props
    // When paginating, reset scroll
    if (
      localizer.neq(nextProps.range[0], range[0], 'minutes') ||
      (scrollToTime instanceof Date &&
        localizer.neq(nextProps.scrollToTime, scrollToTime, 'minutes'))
    ) {
      this.calculateScroll(nextProps)
    }
  }

  gutterRef = ref => {
    this.gutter = ref && findDOMNode(ref)
  }

  handleSelectAlldayEvent = (...args) => {
    //cancel any pending selections so only the event click goes through.
    this.clearSelection()
    notify(this.props.onSelectEvent, args)
  }

  handleSelectAllDaySlot = (slots, slotInfo) => {
    const { onSelectSlot } = this.props

    const start = new Date(slots[0])
    const end = new Date(slots[slots.length - 1])
    end.setDate(slots[slots.length - 1].getDate() + 1)

    notify(onSelectSlot, {
      slots,
      start,
      end,
      action: slotInfo.action,
      resourceId: slotInfo.resourceId,
    })
  }

  clearSelection() {
    clearTimeout(this._selectTimer)
  }

  measureGutter() {
    if (this.measureGutterAnimationFrameRequest) {
      window.cancelAnimationFrame(this.measureGutterAnimationFrameRequest)
    }
    this.measureGutterAnimationFrameRequest = window.requestAnimationFrame(
      () => {
        const width = getWidth(this.gutter)

        if (width && this.state.gutterWidth !== width) {
          this.setState({ gutterWidth: width })
        }
      }
    )
  }

  applyScroll() {
    // If auto-scroll is disabled, we don't actually apply the scroll
    if (this._scrollRatio != null && this.props.enableAutoScroll === true) {
      const content = this.contentRef.current
      content.scrollTop = content.scrollHeight * this._scrollRatio
      // Only do this once
      this._scrollRatio = null
    }
  }

  calculateScroll(props = this.props) {
    const { min, max, scrollToTime, getNow, localizer } = props
    if (!scrollToTime) {
      this._scrollRatio = 0
      return
    }

    const time = scrollToTime === 'currentTime' ? getNow() : scrollToTime
    if (!localizer.inRange(time, min, max, 'minutes')) {
      this._scrollRatio = 0
      return
    }

    const diffMillis = time - min
    const totalMillis = localizer.diff(min, max, 'milliseconds')

    this._scrollRatio = diffMillis / totalMillis
  }

  checkOverflow = () => {
    if (this._updatingOverflow) return

    const content = this.contentRef.current
    let isOverflowing = content.scrollHeight > content.clientHeight

    if (this.state.isOverflowing !== isOverflowing) {
      this._updatingOverflow = true
      this.setState({ isOverflowing }, () => {
        this._updatingOverflow = false
      })
    }
  }

  onDayClick = (date, staffId) => () => {
    date.staffId = staffId
    return this.props.onDrillDown(date, Views.DAY)
  }

  renderGrid(range, events, backgroundEvents, now) {
    let {
      min,
      max,
      components,
      accessors,
      localizer,
      classes
    } = this.props

    const resources = this.memoizedResources(this.props.resources, accessors)
    const groupedEvents = resources.groupEvents(events)

    return resources.map(([id, resource], i) => {
      const {dayShifts} = resource
      const {isFlexboxContainer, flexContainerStaticRows} = this.state
      let rowStyle
      if (isFlexboxContainer && flexContainerStaticRows[i]) {
        rowStyle = {height: flexContainerStaticRows[i], flexGrow: 0, flexBasis: 'auto'}
      }
      return (
        <div className={classes.hmRbcWeekRow} style={rowStyle}>
          <div className={classes.hmRbcStaffContainer} ref={i === 0 ? this.gutterRef : undefined}>
            <div key={resource.id} className={classes.hmRbcOneStaff}>
              {accessors.resourceTitle(resource)}
            </div>
          </div>
          {range.map((date, jj) => {
            const daysEvents = (groupedEvents.get(id) || []).filter(event =>
              localizer.inRange(
                date,
                accessors.start(event),
                accessors.end(event),
                'day'
              )
            ).sort((a, b) => a.start <= b.start ? -1 : 1)
            const dayShift = dayShifts[jj]
            const isWorkingDay = [dayShiftTypes.scheduled.type, dayShiftTypes.modified.type].includes(dayShift.type)

            return (
              <div key={i + '-' + jj} className={cx(classes.hmRbcDay, {isWorkingDay})} onClick={this.onDayClick(date, id)}>
                {daysEvents.map(event => (
                  <Event key={event.id} style={{
                    height: 'auto',
                    color: event.color,
                    backgroundColor: event.backgroundColor,
                    marginBottom: 1
                  }} event={event} title={event.title} isShort />
                ))}
              </div>
            )
          })
        }</div>
      )
    })
  }

  render() {
    let {
      events,
      backgroundEvents,
      range,
      width,
      rtl,
      selected,
      getNow,
      resources,
      components,
      accessors,
      getters,
      localizer,
      showMultiDayTimes,
      longPressThreshold,
      resizable,
      getRootNode,
      classes,
    } = this.props

    width = width || this.state.gutterWidth

    let start = range[0],
      end = range[range.length - 1]

    this.slots = range.length

    let allDayEvents = [],
      rangeEvents = [],
      rangeBackgroundEvents = []

    events.forEach(event => {
      if (inRange(event, start, end, accessors, localizer)) {
        let eStart = accessors.start(event),
          eEnd = accessors.end(event)

        if (
          accessors.allDay(event) ||
          localizer.startAndEndAreDateOnly(eStart, eEnd) ||
          (!showMultiDayTimes && !localizer.isSameDate(eStart, eEnd))
        ) {
          allDayEvents.push(event)
        } else {
          rangeEvents.push(event)
        }
      }
    })

    backgroundEvents.forEach(event => {
      if (inRange(event, start, end, accessors, localizer)) {
        rangeBackgroundEvents.push(event)
      }
    })

    allDayEvents.sort((a, b) => sortEvents(a, b, accessors, localizer))

    return (
      <div
        data-testid={this.props['data-testid']}
        className={clsx(
          'rbc-time-view',
          resources && 'rbc-time-view-resources'
        )}
      >
        <TimeGridHeader
          range={range}
          events={allDayEvents}
          width={width}
          rtl={rtl}
          getNow={getNow}
          localizer={localizer}
          selected={selected}
          resources={this.memoizedResources(undefined, accessors)}
          selectable={this.props.selectable}
          accessors={accessors}
          getters={getters}
          components={components}
          scrollRef={this.scrollRef}
          isOverflowing={this.state.isOverflowing}
          longPressThreshold={longPressThreshold}
          onSelectSlot={this.handleSelectAllDaySlot}
          onSelectEvent={this.handleSelectAlldayEvent}
          onDoubleClickEvent={this.props.onDoubleClickEvent}
          onKeyPressEvent={this.props.onKeyPressEvent}
          onDrillDown={() => null}
          getDrilldownView={() => null}
          resizable={resizable}
          getRootNode={getRootNode}
        />
        <div
          ref={this.contentRef}
          className="rbc-time-content"
          onScroll={this.handleScroll}
        >
          <div className={classes.hmRbcDaysContainer} ref={this.daysContainerRef}>
            <div ref={this.daysInnerContainerRef} style={this.state.isFlexboxContainer ? {display: 'flex', flexDirection: 'column', justifyContent: 'stretch', height: '100%', overflowY: 'auto'} : null}>
              {this.renderGrid(
                range,
                rangeEvents,
                rangeBackgroundEvents,
                getNow()
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(CustomTimeGrid, styles)

CustomTimeGrid.defaultProps = {
  step: 30,
  timeslots: 2,
}
