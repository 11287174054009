import { Views } from '@nlevchuk/react-big-calendar';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Hidden,
  Grid,
} from '@mui/material';
import { ArrowForwardIos as ArrowForwardIcon, ArrowBackIos } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

const { DAY, WEEK, MONTH } = Views;

const useStyles = makeStyles()((theme) => ({
  root: {
    fontFamily: "SF Pro Display !important",
    marginBottom: theme.spacing(),

    '& .MuiPaper-elevation4-33': {
      boxShadow: 'none',
    }
  },
  toolbar: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    padding: '3px 8px',
    backgroundColor: "#f9f9f9",
  },
  tabs: {
    position: 'absolute',
    left: '7px',
  },
  dateNavigator: {
    display: 'flex',
    alignItems: 'center',
  },
  tabFirst: {
    border: '1px solid #EFEFEF',
    borderRadius: '5px',
    borderRight: 'none',
    borderTopRightRadius: 'unset',
    borderBottomRightRadius: 'unset',
  },
  tabSecond: {
    borderTop: '1px solid #EFEFEF',
    borderBottom: '1px solid #EFEFEF',
  },
  tabLast: {
    border: '1px solid #EFEFEF',
    borderRadius: '5px',
    borderLeft: 'none',
    borderTopLeftRadius: 'unset',
    borderBottomLeftRadius: 'unset',
  },
  tabsRoot: {},
  tabRoot: {
    width: '104px',
    minWidth: 'unset',
    fontSize: 14,
    fontWeight: '400',
    textTransform: 'initial',
    color: '#A8B2B9',
    opacity: 1,
    margin: '5px 0 5px 0',
  },
  tabsIndicator: {
    backgroundColor: 'unset',
  },
  tabSelected: {
    color: '#58CB7D',
    fontWeight: 'bold',
    boxShadow: '0 8px 16px 0 rgba(88,203,125,0.1)',
    backgroundColor: '#ffffff',
    border: 'none',
  },
  arrowBox: {
    padding: theme.spacing(),
    width: 'auto',
    cursor: 'pointer',
  },
  leftArrowIcon: {
    transform: 'rotate(180deg)',
  },
  arrowIcon: {
    color: grey[700],
  },
  dateTitle: {
    fontSize: theme.spacing(9/4),
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#000000',
    margin: theme.spacing(0, 1),
    padding: theme.spacing(),
  },
  dateTitleText: {
    borderBottom: '1px dashed',
  },
}));

const pluralForms = {
  [DAY]: 'days',
  [WEEK]: 'weeks',
  [MONTH]: 'months',
};

const goToBack = (view, date, onNavigate) => () => {
  const pluralName = pluralForms[view];
  const newDate = moment(date).subtract(1, pluralName).toDate();
  onNavigate('prev', newDate);
};

const goToNext = (view, date, onNavigate) => () => {
  const pluralName = pluralForms[view];
  const newDate = moment(date).add(1, pluralName).toDate();
  onNavigate('next', newDate);
};

const dateTitle = (view, date, localizer) => {
  switch(view) {
    case DAY:
      return <div>{localizer.format(date, 'ddd, MMM D, YYYY')}</div>;
    case WEEK: {
      const firstOfWeek = localizer.startOfWeek();
      const start = localizer.startOf(date, 'week', firstOfWeek);
      const end = localizer.endOf(date, 'week', firstOfWeek);

      return (
        <div>
          <span>{localizer.format(start, 'MMM D')}</span>
          <span>&nbsp;&nbsp;&mdash;&nbsp;&nbsp;</span>
          <span>{localizer.format(end, 'MMM D')}</span>
        </div>
      );
    }
    case MONTH:
      return <div>{localizer.format(date, 'MMMM YYYY')}</div>;
    default:
      console.log('Unknown view name');
  };
};

const ToolBar = (props) => {
  const {
    date,
    view,
    onNavigate,
    onView,
    localizer,
  } = props;
  const {classes, cx} = useStyles();

  const changeViewOnMobile = () => {
    return (view === MONTH) ? onView(DAY) : onView(MONTH);
  };

  return (
    <div className={classes.root}>
      <div className="calendar-navigation">
        <AppBar position="static" style={{boxShadow: "2px"}}>
          <Toolbar className={classes.toolbar}>
            <Hidden mdDown>
              <div className={classes.tabs}>
                <Tabs
                  classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabsIndicator,
                  }}
                  value={view}
                >
                  <Tab
                    value="day"
                    label="Day"
                    data-testid="dayTab"
                    onClick={() => onView(DAY)}
                    className={classes.tabFirst}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                  />
                  <Tab
                    value="week"
                    label="Week"
                    data-testid="weekTab"
                    onClick={() => onView(WEEK)}
                    className={classes.tabSecond}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                  />
                  <Tab
                    value="month"
                    label="Month"
                    data-testid="monthTab"
                    onClick={() => onView(MONTH)}
                    className={classes.tabLast}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                  />
                </Tabs>
              </div>
            </Hidden>

            <div className={classes.dateNavigator}>
              <Grid
                container
                onClick={goToBack(view, date, onNavigate)}
                className={classes.arrowBox}
              >
                <ArrowForwardIcon
                  data-testid="calendarBackButton"
                  className={cx(
                    classes.arrowIcon,
                    classes.leftArrowIcon,
                  )}
                />
              </Grid>
              <Hidden mdDown>
                <div className={classes.dateTitle}>
                  {dateTitle(view, date, localizer)}
                </div>
              </Hidden>
              <Hidden mdUp>
                <div
                  onClick={changeViewOnMobile}
                  className={classes.dateTitle}
                >
                  <div className={classes.dateTitleText}>
                    {dateTitle(view, date, localizer)}
                  </div>
                </div>
              </Hidden>
              <Grid
                container
                onClick={goToNext(view, date, onNavigate)}
                className={classes.arrowBox}
              >
                <ArrowForwardIcon data-testid="calendarNextButton" className={classes.arrowIcon} />
              </Grid>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
};

export default ToolBar;
