import { forwardRef } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  FormControl,
  Grid,
  Hidden,
  Box
} from '@mui/material';
import { Views } from '@nlevchuk/react-big-calendar';
import { grey } from '@mui/material/colors';
import { cx } from '@emotion/css'
import { withStyles } from 'tss-react/mui';

import { prepareOptions } from '../../../../shared_client_utils/formUtils';
import AppBarLogo from '../AppBarLogo';
import Checkbox from '../../../../shared_components/Checkbox';
import { navbarHeightMultiplier } from '../../../../shared_client_utils/theme';

const styles = (theme) => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(0, 2),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  logoBox: {
    height: theme.spacing(navbarHeightMultiplier),
    alignItems: 'center',
  },
  nav: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 1, 1, 1),
    height: `calc(100vh - ${theme.spacing(navbarHeightMultiplier + 1)})`,
    overflowY: 'auto',
  },
  titleItem: {
    paddingBottom: 0,
    backgroundColor: '#fff',
  },
  bodyItem: {
    paddingBottom: 0,

    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  listItemTextPrimary: {
    fontWeight: 500,
  },
  checkbox: {
    '& span': {
      color: '#4C4C4C',
    }
  },
  disabled: {
    '& span': {
      color: grey[500],
    }
  },
});

const StaffLabel = ({ staff }) => {
  const { isArchived, visibleInCalendar, alias } = staff;

  if (isArchived) {
    return (
      <div title="The staff was archived">
        <span>{alias}</span>
        <span> (archived)</span>
      </div>
    );
  } else if (!visibleInCalendar) {
    return (
      <div title="The staff was set up as invisible for calendar">
        <span>{alias}</span>
        <span> (invisible)</span>
      </div>
    );
  }

  return alias;
};

const SideBar = (props) => {
  const {
    classes,
    locations,
    selectedLocation,
    onClickLocation,
    selectedStaffId,
    onClickStaff,
    currentView,
    currentDate,
    staff,
    allRosteredStaffOption,
    auth,
    isMobileScreen,
  } = props;

  const { currentStaff: { isStaff } } = auth;
  const preparedLocations = prepareOptions(locations.byId);

  const locationItem = (location) => forwardRef((props, ref) => (
    <li {...props} ref={ref}>
      <FormControl>
        <Checkbox
          disableRipple
          label={location.name}
          onClick={onClickLocation(location)}
          checked={location.id === selectedLocation.id}
          className={classes.checkbox}
        />
      </FormControl>
    </li>
  ));

  const staffItem = (staff) => forwardRef((props, ref) => (
    <li {...props} ref={ref}>
      <FormControl>
        <Checkbox
          disableRipple
          label={<StaffLabel staff={staff} />}
          onClick={onClickStaff(staff.id)}
          checked={staff.id === selectedStaffId}
          className={cx(
            classes.checkbox,
            (staff.isArchived || !staff.visibleInCalendar) ? classes.disabled : '',
          )}
        />
      </FormControl>
    </li>
  ));

  return (
    <Grid container className={classes.root}>
      <Hidden lgUp>
        <Grid container className={classes.logoBox}>
          <div><AppBarLogo {...props} /></div>
        </Grid>
      </Hidden>

      <List
        component="nav"
        className={classes.nav}
      >
        <ListItem disableGutters className={cx(classes.titleItem)}>
          <ListItemText
            primary="Location"
            classes={{
              primary: classes.listItemTextPrimary,
            }}
          />
        </ListItem>
        <List className={classes.listView}>
          {preparedLocations.map((location) => (
            <ListItem
              disableGutters
              data-testid={`filterLocation-${location.id}`}
              key={location.name}
              className={classes.bodyItem}
              component={locationItem(location)}
            />
          ))}
        </List>
        <div>
          <ListItem disableGutters className={cx(classes.titleItem)}>
            <ListItemText
              primary="Staff"
              classes={{
                primary: classes.listItemTextPrimary,
              }}
            />
          </ListItem>
          <List className={classes.listView}>
            <Box component="li" sx={{ display: { sm: currentView === Views.MONTH ? 'none' : 'block', xs: 'none' } }} disableGutters className={classes.bodyItem} data-testid="filterStaff-all">
              <FormControl>
                <Checkbox
                  disableRipple
                  label={allRosteredStaffOption.label}
                  onClick={onClickStaff(allRosteredStaffOption.value)}
                  checked={allRosteredStaffOption.value === selectedStaffId}
                  className={classes.checkbox}
                />
              </FormControl>
            </Box>
            {staff.map((oneStaff) => (
              <ListItem
                disableGutters
                  data-testid={`filterStaff-${oneStaff.id}`}
                key={oneStaff.fullName}
                className={classes.bodyItem}
                component={staffItem(oneStaff)}
              />
            ))}
          </List>
        </div>
      </List>
    </Grid>
  );
};

export default withStyles(SideBar, styles);
